import { Card, Col, Modal, Row, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const INSTRUCTIONS = [
  'This 20 case skill test will evaluate your ability to make radiological diagnoses on a virtual Radiology reading station.',
  'Each case will start with relevant history, labs, and physical exam findings followed by imaging (CTs, x-rays, and ultrasounds).',
  'On average, you will have 9 minutes to review the imaging and type your findings and impression for each case.',
  'You may revisit cases at anytime.',
  'The session will automatically end after 3 hours, and your results will be emailed to your PD or the faculty physician you listed.',
  'Once you register for the exam, a code will be emailed to you. Please use this code to resume your exam in the event your internet connection is disrupted. The code can only be used once.',
];

const Resident = () => {
  const navigate = useNavigate();

  const info = () => {
    Modal.info({
      title: 'Important Note',
      content: (
        <ul>
          <li style={{ fontSize: '1.1em' }}>
            This tutorial will demo how each case will be presented during the exam. Please use the tutorial to familiarize yourself with the different buttons on each webpage and how to manipulate
            the images.
          </li>
          <li style={{ fontSize: '1.1em' }}>General exam format: For each case, you will be shown the case history followed by the images. Type your answers and move on the next case.</li>
          <li style={{ fontSize: '1.1em' }}>You can edit or review your answers at any time. Note: you will be asked to do a final review immediately before submitting your exam.</li>
        </ul>
      ),
      okText: 'Proceed',
      onOk() {
        navigate('/tutorial/history');
      },
      width: 800,
    });
  };

  return (
    <Row>
      <Col xs={24}>
        <Row align='center'>
          <Title style={{ color: '#d23f57' }}>Radiology Skill Test</Title>
        </Row>
        <Card title={<Title level={2}>Instructions</Title>}>
          <ul>
            {INSTRUCTIONS.map((text, index) => (
              <li key={index}>
                <Title level={4} style={{ fontWeight: 400 }}>
                  {text}
                </Title>
              </li>
            ))}
          </ul>
        </Card>
        <Row gutter={[16, 16]} className='mt-1'>
          <Col xs={24} md={7}>
            <Link to='/resume-exam'>
              <Card hoverable className='center-text' style={{ backgroundColor: '#CCD4DC' }}>
                <Title level={4}>Resume Previous Exam</Title>
                <Text>(My connection was disrupted)</Text>
              </Card>
            </Link>
          </Col>
          <Col xs={24} md={9}>
            <Card hoverable className='center-text' style={{ backgroundColor: '#d23f57', cursor: 'pointer' }} onClick={info}>
              <Title style={{ color: '#fff' }} level={4}>
                Proceed To Tutorial
              </Title>
              <Text style={{ color: '#fff' }}>(Recommended)</Text>
            </Card>
          </Col>

          <Col xs={24} md={8}>
            <Link to='/information'>
              <Card hoverable className='center-text' style={{ backgroundColor: '#042444' }}>
                <Title style={{ color: '#fff' }} level={4}>
                  Start New Exam Registration
                </Title>
                <Text style={{ color: '#fff' }}>(Skip tutorial)</Text>
              </Card>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Resident;
