import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Input, Modal, Row, Tour, Typography } from 'antd';
import { ArrowRightOutlined, ExclamationCircleFilled, ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

const { Text, Title } = Typography;
const { confirm } = Modal;

const ReviewPage = () => {
  const navigate = useNavigate();

  const toCaseButton = useRef(null);
  const updateAnswer = useRef(null);
  const inputFields = useRef(null);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleSubmit = () => {
    confirm({
      title: 'You are about to end your exam!',
      icon: <ExclamationCircleFilled />,
      content: 'Your answer have been saved. Your results will be made available to your PD or faculty physician. Do you wish to continue?',
      okText: 'End Exam',
      width: 600,
      onOk() {
        navigate('/information');
      },
    });
  };

  const steps = [
    {
      title: 'Tutorial Case',
      description: 'You can review and edit your findings and impression for all 20 cases on this page.',
      target: () => inputFields.current,
    },
    {
      title: 'Update answer',
      description: 'Each time you edit your findings or impression, please DO NOT forget to click “Update Answer” to save the changes.',
      target: () => updateAnswer.current,
    },
    {
      title: 'Go To Case',
      description: 'Click this button to review the images for the case again.',
      target: () => toCaseButton.current,
      placement: 'right',
    },
  ];

  return (
    <div className='content-style'>
      <Tour open={open} onClose={() => setOpen(false)} steps={steps} />

      <Title level={4} style={{ color: '#fff' }}>
        Remaining Time: 2:59:59
      </Title>
      <Row align='center'>
        <Title style={{ color: '#d23f57' }}>Review Exam</Title>
      </Row>
      <Card
        ref={inputFields}
        className='mt-1'
        title='Tutorial Case'
        extra={
          <Link to='/tutorial/case'>
            <Button ref={toCaseButton} type='dashed' style={{ color: 'red', borderColor: 'red' }}>
              Go To Case <ArrowRightOutlined />
            </Button>
          </Link>
        }>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <label htmlFor='findings'>Findings</label>
            <Input.TextArea rows={1} autoSize defaultValue='Normal orbits. Left kidney with J-J stent and severe hydronephrosis.' size='large' />
          </Col>
          <Col xs={24} sm={12}>
            <label htmlFor='impression'>Impression</label>
            <Input.TextArea rows={1} autoSize defaultValue='Normal eyes. Concern for stent failure given severe left hydronephrosis.' size='large' />
          </Col>
        </Row>
        <Row justify='center' className='mt-1'>
          <Button ref={updateAnswer} type='primary' style={{ backgroundColor: '#045CBC', color: '#fff' }}>
            Update Answer
          </Button>
        </Row>
      </Card>

      <Row justify='space-between' align='middle' className='mt-1'>
        <Button type='primary' size='large' onClick={() => navigate('/tutorial/case')} style={{ backgroundColor: 'lightgray', color: 'black', fontWeight: 500 }} icon={<ArrowLeftOutlined />}>
          Tutorial Case
        </Button>

        <Button size='large' type='primary' onClick={handleSubmit}>
          Submit & End Exam
        </Button>
      </Row>

      <Row justify='center' style={{ marginTop: '4em' }}>
        <Link to='/information'>
          <Card className='center-text' style={{ backgroundColor: '#d23f57', cursor: 'pointer' }}>
            <Title style={{ color: '#fff' }} level={4}>
              Start New Exam Registration
            </Title>
            <Text style={{ color: '#fff' }}>(I have completed the tutorial)</Text>
          </Card>
        </Link>
      </Row>
    </div>
  );
};

export default ReviewPage;
