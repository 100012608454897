import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const TutorialMenuTopics = ({ onClick }) => {
  const location = useLocation();

  const [path, setPath] = useState('');

  useEffect(() => {
    setPath(location.pathname);
    console.log(location.pathname);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Menu mode='inline' selectedKeys={[path.split('/')[2]]} onClick={() => onClick()}>
        <Menu.Item key='history'>
          Tutorial <Link to='/tutorial/history' />
        </Menu.Item>
        <Menu.Item key='review'>
          Review Exam <Link to='/tutorial/review' />
        </Menu.Item>
      </Menu>
    </>
  );
};

export default TutorialMenuTopics;
