import { useEffect } from 'react';
import { message, Modal, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { CarryOutOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { DeleteCaseStudy, GetCaseStudyList } from '../../api';

const { Title } = Typography;
const { confirm } = Modal;

const CaseStudyList = () => {
  const navigate = useNavigate();

  const { data: list, isLoading } = GetCaseStudyList();
  const { mutate: deleteCase } = DeleteCaseStudy();

  useEffect(() => {
    if (!sessionStorage.getItem('token')) {
      navigate('/login');
    }
  }, []);

  const deleteCaseStudy = (value) => {
    confirm({
      title: 'Are you sure delete this case study?',
      icon: <ExclamationCircleOutlined />,
      content: "Once deleted you won't be able to recover your case study. ",
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        deleteCase(
          { q_id: value.id },
          {
            onSuccess: () => {
              message.success('Case study deleted successfully!');
            },
          }
        );
      },
    });
  };

  const columns = [
    {
      title: 'Case Num',
      dataIndex: 'case_num',
      key: 'case_num',
    },
    {
      title: 'HPI',
      dataIndex: 'hpi',
      key: 'hpi',
    },
    {
      title: 'Labs',
      dataIndex: 'labs',
      key: 'labs',
    },
    {
      title: 'Vitals',
      dataIndex: 'vitals',
      key: 'vitals',
    },
    {
      title: 'Modality',
      dataIndex: 'modality',
      key: 'modality',
    },
    {
      title: 'Exam',
      dataIndex: 'exam',
      key: 'exam',
    },
    {
      title: 'Official Findings',
      dataIndex: 'official_findings',
      key: 'official_findings',
    },
    {
      title: 'Official Impression',
      dataIndex: 'official_impression',
      key: 'official_impression',
    },
    {
      title: 'Dicom',
      dataIndex: 'json_url',
      key: 'json_url',
      render: (text) => (
        <Tooltip title='Open DICOM'>
          <a href={text} target='_blank' rel='noreferrer'>
            {text && <CarryOutOutlined />}
          </a>
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          {!record.json_url && (
            <Tooltip title='Add Dicom'>
              <PlusCircleOutlined onClick={() => navigate(`/admin/case-study/${record.id}`)} />
            </Tooltip>
          )}
          <DeleteOutlined style={{ color: 'red' }} onClick={() => deleteCaseStudy(record)} />
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Title level={2} className='center'>
        Case Study List
      </Title>
      <Table columns={columns} dataSource={list} scroll={{ x: 1500 }} />
    </Spin>
  );
};

export default CaseStudyList;
