import { useRef, useState } from 'react';
import { Button, Col, Drawer, Layout, Row, Tour, Typography } from 'antd';
import { MenuOutlined, StopOutlined } from '@ant-design/icons';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import TutorialMenuTopics from '../../components/TutorialMenuTopics';
import { useEffect } from 'react';

const { Title } = Typography;

const { Content, Header } = Layout;

const History = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const menuButton = useRef(null);
  const timeCount = useRef(null);
  const endExamButton = useRef(null);
  const caseHistory = useRef(null);
  const toCaseButton = useRef(null);

  const [navIsVisible, setNavIsVisible] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const closeSidenav = () => {
    setNavIsVisible(false);
  };

  const openSidenav = () => {
    setNavIsVisible(true);
  };

  const steps = [
    {
      title: 'Case History',
      description: 'Each case starts with a history.',
      target: () => caseHistory.current,
      placement: 'top',
    },
    {
      title: 'Menu',
      description: 'Use this button to skip between cases or review your exam.',
      target: () => menuButton.current,
      placement: 'leftTop',
    },
    {
      title: 'Timer',
      description: 'The exam will automatically end after 3 hours.',
      target: () => timeCount.current,
      placement: 'left',
    },
    {
      title: 'End Exam',
      description: 'Click here to end the exam at any point. Note:  you will be asked to review your answers before submitting the exam.',
      target: () => endExamButton.current,
      placement: 'rightTop',
    },
    {
      title: 'To Case',
      description: 'After you have reviewed the history, click here to view the images.',
      target: () => toCaseButton.current,
      placement: 'bottomRight',
    },
  ];

  return (
    <>
      <Layout>
        <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
        <Drawer title='RadMe' placement='left' onClose={closeSidenav} open={navIsVisible}>
          <TutorialMenuTopics onClick={closeSidenav} />
        </Drawer>
        <Header className='header-style'>
          <Button ref={menuButton} className='menu' type='primary' icon={<MenuOutlined />} onClick={openSidenav} />
          {pathname !== '/exam/review' ? (
            <Button ref={endExamButton} type='primary' size='large' icon={<StopOutlined />} onClick={() => navigate('/tutorial/review')}>
              End Exam
            </Button>
          ) : null}
        </Header>
        <Content style={{ minHeight: '92%', padding: '12px', background: '#000', color: '#E3E3E7' }}>
          <Title ref={timeCount} level={4} style={{ color: '#fff', display: 'inline-flex' }}>
            Remaining Time: 2:59:59
          </Title>
          <Row>
            <Col xs={24}>
              <div style={{ height: '80vh', display: 'grid', placeItems: 'center' }}>
                <ul ref={caseHistory}>
                  <Title style={{ color: '#CBCCCC' }}>Tutorial Case History</Title>
                  <li>
                    <Title style={{ color: '#CBC' }} level={2}>
                      HPI: 33-year-old female with history of <br />
                      obstructed distal left ureteral stone s/p <br />
                      stenting by Urology 3 months ago. Pt lost to follow up.
                      <br />
                      Presents today with LUQ pain with concern <br />
                      for stent failure vs. pyelonephritis. <br />
                      Additional complaint of possible foreign body in the left eye.
                    </Title>
                  </li>
                  <li>
                    <Title style={{ color: '#CBC' }} level={2}>
                      Vitals: Normal
                    </Title>
                  </li>
                  <li>
                    <Title style={{ color: '#CBC' }} level={2}>
                      Labs: Normal BMP and CBC
                    </Title>
                  </li>
                  <li>
                    <Title style={{ color: '#CBC' }} level={2}>
                      Exam: Left eye conjunctiva is inflamed and red. LUQ tenderness
                    </Title>
                  </li>
                </ul>
              </div>
              <Row justify='space-between'>
                <div />
                <Link to='/tutorial/case'>
                  <Button ref={toCaseButton} size='large' type='primary'>
                    To Case <ArrowRightOutlined />
                  </Button>
                </Link>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default History;
