import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import { Route, Routes } from "react-router-dom";

import CaseStudyDetails from "./pages/CaseStudy/CaseStudyDetails";
import CaseStudyList from "./pages/CaseStudy/CaseStudyList";
import CreateCaseStudy from "./pages/CaseStudy/CreateCaseStudy";
import ExamineeList from "./pages/CaseStudy/ExamineeList";
import ExamineeTestDetails from "./pages/CaseStudy/ExamineeTestDetails";
import Disclaimer from "./pages/Disclaimer";
import CaseName from "./pages/Exam/CaseName";
import ExamPage from "./pages/Exam/ExamPage";
import ReviewExam from "./pages/Exam/ReviewExam";
import HomePage from "./pages/HomePage";
import Information from "./pages/Information";
import Login from "./pages/Login";
import MedicalStudent from "./pages/MedicalStudent";
import Resident from "./pages/Resident";
import ResumeExam from "./pages/ResumeExam";
import StudentPrep from "./pages/StudentPrep/StudentPrep";
import Tutorial from "./pages/Tutorial";
import Case from "./pages/Tutorial/Case";
import History from "./pages/Tutorial/History";
import ReviewPage from "./pages/Tutorial/ReviewPage";

import AdminLayout from "./layouts/AdminLayout";
import CommonLayout from "./layouts/CommonLayout";
import ExamLayout from "./layouts/ExamLayout";
import TutorialLayout from "./layouts/TutorialLayout";

import "./App.css";

const queryClient = new QueryClient();

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#d23f57",
        },
      }}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<CommonLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/resident" element={<Resident />} />
            <Route path="/login" element={<Login />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/resume-exam" element={<ResumeExam />} />
            <Route path="/medical-student" element={<MedicalStudent />} />
            <Route path="/information" element={<Information />} />
          </Route>

          <Route element={<AdminLayout />}>
            <Route path="/admin/create-case-study" element={<CreateCaseStudy />} />
            <Route path="/admin/case-study-list" element={<CaseStudyList />} />
            <Route path="/admin/examinee" element={<ExamineeList />} />
            <Route path="/admin/exam-details/:id" element={<ExamineeTestDetails />} />
            <Route path="/admin/case-study/:id" element={<CaseStudyDetails />} />
          </Route>

          <Route element={<ExamLayout />}>
            <Route path="/history/:id" element={<CaseName />} />
            <Route path="/exam/:id" element={<ExamPage />} />
            <Route path="/exam/review" element={<ReviewExam />} />
          </Route>

          <Route element={<TutorialLayout />}>
            <Route path="/tutorial/case" element={<Case />} />
            <Route path="/tutorial/review" element={<ReviewPage />} />
          </Route>

          <Route path="/student-prep/:prepType/:questionId" element={<StudentPrep />} />

          <Route path="/tutorial/history" element={<History />} />
        </Routes>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
