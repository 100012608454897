import { Menu } from "antd";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const PrepMenuTopics = ({ onClick }) => {
  const { prepType, questionId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Menu mode="inline" selectedKeys={[questionId]} onClick={() => onClick()}>
        {JSON.parse(sessionStorage.getItem("prepQuestions"))?.map((el, i) => (
          <Menu.Item key={el.id}>
            Question {i + 1} <Link to={`/student-prep/${prepType}/${el.id}`} />
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
};

export default PrepMenuTopics;
