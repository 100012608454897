import { Button, Card, Col, Descriptions, Divider, Row, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';

import { GetQuestionListByTest } from '../../api';

const ExamineeTestDetails = () => {
  const { id } = useParams();

  const { data: testCases, isLoading: isGettingTestCases } = GetQuestionListByTest(id);
  console.log(testCases);

  return (
    <Spin spinning={isGettingTestCases}>
      <Link to={-1}>
        <Button style={{ marginBottom: '1em' }} icon={<ArrowLeftOutlined />}>
          Back
        </Button>
      </Link>

      <Row gutter={[16, 16]}>
        {testCases?.map((cases) => (
          <Col xs={24}>
            <Card>
              <Descriptions title={cases?.question?.case_num} bordered>
                <Descriptions.Item label='HPI'>{cases?.question?.hpi}</Descriptions.Item>
                <Descriptions.Item label='Labs'>{cases?.question?.labs}</Descriptions.Item>
                <Descriptions.Item label='Modality'>{cases?.question?.modality}</Descriptions.Item>
                <Descriptions.Item label='Vitals'>{cases?.question?.vitals}</Descriptions.Item>
                <Descriptions.Item label='Exam'>{cases?.question?.exam}</Descriptions.Item>
                <Descriptions.Item label='Official Findings'>{cases?.question?.official_findings}</Descriptions.Item>
                <Descriptions.Item label='Official Impression'>{cases?.question?.official_impression}</Descriptions.Item>
                <Descriptions.Item label='Dicom File'>
                  <a href={cases?.question?.json_url} target='_blank'>
                    See dicom files
                  </a>
                </Descriptions.Item>
              </Descriptions>

              <Divider />

              <Descriptions title='Answer'>
                <Descriptions.Item label='Findings'>{cases?.question?.answer?.findings}</Descriptions.Item>
                <Descriptions.Item label='Impression'>{cases?.question?.answer?.impression}</Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

export default ExamineeTestDetails;
