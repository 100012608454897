import { Button, Card, Col, Form, Input, Row, Spin, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { AdminLogin } from '../api';

const { Title } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate, isLoading } = AdminLogin();

  const handleLogin = (values) => {
    console.log(values);
    mutate(values, {
      onSuccess: (data) => {
        navigate('/admin/examinee');
        sessionStorage.setItem('token', data?.data?.data?.token);
      },
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col xs={24}>
          <Row align='center'>
            <Title style={{ color: '#d23f57' }}>System Admin</Title>
          </Row>
          <Card>
            <Form form={form} onFinish={handleLogin}>
              <Row>
                <Col xs={24} md={10} lg={6} className='mt-1'>
                  <label htmlFor='email'>Email</label>
                </Col>
                <Col xs={24} md={14} lg={18}>
                  <Form.Item name='email' rules={[{ required: true, message: 'Please input your email!' }]}>
                    <Input size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} md={10} lg={6} className='mt-1'>
                  <label htmlFor='password'>Password</label>
                </Col>
                <Col xs={24} md={14} lg={18}>
                  <Form.Item name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input.Password size='large' />
                  </Form.Item>
                </Col>
              </Row>
              <Row align='center'>
                <Form.Item>
                  <Button type='primary' htmlType='submit' size='large'>
                    Login
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default Login;
