import { Card, Col, message, Row, Spin, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { GetAllPrepQuestions } from "../api";

const { Title } = Typography;

const questionTypes = [
  {
    label: "Neuro",
    url: "/student-prep/Neuro",
  },
  {
    label: "Chest",
    url: "/student-prep/Chest",
  },
  {
    label: "Body",
    url: "/student-prep/Body",
  },
  {
    label: "MSK",
    url: "/student-prep/MSK",
  },
];

const MedicalStudent = () => {
  const navigate = useNavigate();

  const { mutate: prepQuestions, isLoading: prepQuestionsLoading } = GetAllPrepQuestions();

  const onTypeClick = (type) => {
    prepQuestions(
      { prep_type: type },
      {
        onSuccess: (response) => {
          const responseData = response?.data?.data;

          if (Array.isArray(responseData) && responseData.length > 0) {
            sessionStorage.setItem("prepQuestions", JSON.stringify(responseData));
            navigate(`/student-prep/${type}/${responseData[0].id}`);
          } else {
            message.error("No questions found");
          }
        },
      }
    );
  };

  return (
    <Spin spinning={prepQuestionsLoading}>
      <Row gutter={[16, 16]} className="mt-1">
        <Col xs={24}>
          <Title level={2}>RadMe - Virtual Reading Station for Learning Radiology</Title>
        </Col>
        {questionTypes.map((item, index) => (
          <Col xs={24} md={12} lg={6} key={index}>
            <Card hoverable className="py-6 center-text" style={{ backgroundColor: "#CCD4DC" }} onClick={() => onTypeClick(item.label)}>
              <Title level={3}>{item.label}</Title>
            </Card>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

export default MedicalStudent;
