import { Button, Card, Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const { Title } = Typography;

const Disclaimer = () => {
  const navigate = useNavigate();
  const firstCaseId = JSON.parse(sessionStorage.getItem('cases'))[0].value;

  const handleBeginExam = () => {
    sessionStorage.setItem('remaining', moment().add(180, 'm'));
    navigate(`/history/${firstCaseId}`);
  };

  return (
    <Row>
      <Col xs={24}>
        <Row justify='center'>
          <Title style={{ color: '#d23f57' }}>Disclaimer</Title>
        </Row>
        <Card>
          <Title level={4} style={{ fontWeight: 400 }}>
            The Radiology Skills Test is designed to assess your ability to use imaging to diagnose disease that is appropriate for your training level. The results are intended to be used by the
            program directory/faculty to identify areas of improvement. Please refrain from discussing cases presented in this exam with other trainees at your institution.
          </Title>
        </Card>
        <Row justify='center' className='mt-1'>
          <Button size='large' type='primary' onClick={handleBeginExam}>
            Begin Exam
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default Disclaimer;
