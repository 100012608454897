import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Spin, Typography } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import { EndTheExam, GetSingleCaseStudyForStudent, SaveCurrentCase, SyncCaseStudiesWithTime } from '../../api';
import Notification from '../../utils/Notification';

const { Title } = Typography;

const CaseName = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // calculate remaining minutes from now to end
  const nowTime = moment(new Date());
  const endTime = moment(sessionStorage.getItem('remaining'));
  const duration = moment.duration(endTime.diff(nowTime));
  const remainingMinutes = parseInt(duration.asMinutes());

  SyncCaseStudiesWithTime(sessionStorage.getItem('testId'), remainingMinutes);
  const { mutate: caseStudyDetails, isLoading } = GetSingleCaseStudyForStudent();
  const { mutate: saveTest } = SaveCurrentCase();
  const { mutate: submitTest, isLoading: endingExam } = EndTheExam();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [caseStudyData, setCaseStudyData] = useState();
  const [remainingTime, setRemainingTime] = useState('');

  document.addEventListener('fullscreenchange', exitHandler);
  document.addEventListener('webkitfullscreenchange', exitHandler);
  document.addEventListener('mozfullscreenchange', exitHandler);
  document.addEventListener('MSFullscreenChange', exitHandler);

  function exitHandler() {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      // closeFullscreen();
      setIsFullScreen(false);
    }
  }

  useEffect(() => {
    // call timer
    setTimer();

    // get case study data
    const body = {
      skill_test_id: sessionStorage.getItem('testId'),
      q_id: id,
    };

    caseStudyDetails(body, {
      onSuccess: (data) => {
        setCaseStudyData(data.data.data);
        form.setFieldsValue({ findings: data.data.data.answer?.findings, impression: data.data.data.answer?.impression });
      },
    });
  }, [id]);

  const setTimer = () => {
    const countTime = setInterval(() => {
      const distance = moment(sessionStorage.getItem('remaining')).valueOf() - moment().valueOf();

      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (minutes < 10 && seconds > 9) setRemainingTime(`0${hours}:0${minutes}:${seconds}`);
      else if (minutes < 10 && seconds < 9) setRemainingTime(`0${hours}:0${minutes}:0${seconds}`);
      else if (seconds < 10) setRemainingTime(`0${hours}:${minutes}:0${seconds}`);
      else setRemainingTime(`0${hours}:${minutes}:${seconds}`);

      if (distance < 0) {
        setRemainingTime(`00:00:00`);
        clearInterval(countTime);

        submitTest(
          { skill_test_id: sessionStorage.getItem('testId') },
          {
            onSuccess: () => {
              Modal.info({
                title: <Title level={3}>Time Up!!!</Title>,
                content: <Title level={4}>The exam has ended. Your answer have been saved. The results will be made available to your program director / faculty physician.</Title>,
                onOk() {
                  navigate('/');
                  sessionStorage.clear();
                },
                width: 600,
              });
            },
          }
        );
      }
    }, 1000);
  };

  const handleFullSCreen = () => {
    if (isFullScreen) {
      closeFullscreen();
      setIsFullScreen(false);
    } else {
      openFullScreen();
      setIsFullScreen(true);
    }
  };

  const openFullScreen = () => {
    let elem = document.getElementById('rou');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  const handleSubmitAnswer = (value) => {
    const body = {
      skill_test_id: sessionStorage.getItem('testId'),
      q_id: id,
      findings: value.findings,
      impression: value.impression,
    };

    saveTest(body, {
      onSuccess: () => {
        const QuesSet = JSON.parse(sessionStorage.getItem('cases'));
        const currentPos = QuesSet.findIndex((el) => el.value === +id);

        if (QuesSet[QuesSet.length - 1].value === +id) {
          navigate('/exam/review');
        } else {
          navigate(`/history/${QuesSet[currentPos + 1]?.value}`);
        }
        form.resetFields();
      },
    });
  };

  return (
    <Spin spinning={isLoading || endingExam}>
      <Row>
        <Col xs={24}>
          <div id='rou'>
            <Row justify='space-between'>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}>
                <Title level={4} style={{ color: '#fff', padding: `${isFullScreen ? '1em 0 0 1em' : '0 0 0 1em'}` }}>
                  Remaining Time: {remainingTime}
                </Title>
              </div>
              <div>
                {isFullScreen ? (
                  <FullscreenExitOutlined onClick={handleFullSCreen} style={{ fontSize: '2em', padding: '1em 1em 0 1em' }} />
                ) : (
                  <FullscreenOutlined onClick={handleFullSCreen} style={{ fontSize: '2em', padding: '0 1em 0 1em' }} />
                )}
              </div>
            </Row>
            <embed src={caseStudyData?.json_url} style={{ width: '100%', height: `${isFullScreen ? '92vh' : '71vh'}` }} />
          </div>
          <Form form={form} onFinish={handleSubmitAnswer} scrollToFirstError>
            <Row justify='space-between'>
              <Link to={`/history/${id}`}>
                <Button size='large' type='primary' style={{ marginTop: '2em' }} icon={<ArrowLeftOutlined />}>
                  History
                </Button>
              </Link>

              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <label style={{ color: '#CBC', fontSize: '20px' }} htmlFor='findings'>
                    Findings
                  </label>
                  <Form.Item name='findings'>
                    <Input.TextArea size='large' placeholder='Type answer here' style={{ minWidth: '400px' }} rows={1} autoSize />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <label style={{ color: '#CBC', fontSize: '20px' }} htmlFor='impression'>
                    Impression
                  </label>
                  <Form.Item name='impression'>
                    <Input.TextArea size='large' placeholder='Type answer here' style={{ minWidth: '400px' }} rows={1} autoSize />
                  </Form.Item>
                </Col>
              </Row>
              <Button size='large' type='primary' htmlType='submit' style={{ marginTop: '2em' }}>
                {JSON.parse(sessionStorage.getItem('cases'))[JSON.parse(sessionStorage.getItem('cases')).length - 1].value === +id ? 'Review Exam' : 'Next Case'} <ArrowRightOutlined />
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default CaseName;
