import { useState } from 'react';
import { Button, Card, Col, Form, Input, message, Modal, Row, Select, Spin, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

import { SPECIALTY, TRAINING_STATUS, MED_STUDENT_TRAINING_STATUS } from '../utils/Constant';
import { CreateSkillTest } from '../api';
import Notification from '../utils/Notification';

const { Paragraph, Title, Text } = Typography;
const { Option } = Select;

const Information = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { mutate: newTest, isLoading } = CreateSkillTest();

  const [isModalOpen, setIsModalOpen] = useState({ visible: false, code: '' });
  const [medStudentSelected, setMedStudentSelected] = useState(false);

  const handleSpecialtyChange = (value) => {
    if (value === 'Medical Student') {
      setMedStudentSelected(true);
    } else {
      setMedStudentSelected(false);
    }
  };

  const submitInformation = (values) => {
    sessionStorage.clear();

    if (values.email.trim() === values.director_email.trim()) {
      return Notification('Your email and the Program Director or Faculty email cannot be the same.', 'error');
    }

    const body = {
      f_name: values.first_name,
      l_name: values.last_name,
      email: values.email,
      specialty: values.specialty,
      training_status: values.training_status,
      program_name: values.program_name,
      director_name: values.director_name,
      director_email: values.director_email,
    };

    newTest(body, {
      onSuccess: (data) => {
        message.success('You have registered for new exam!');
        sessionStorage.setItem('cases', JSON.stringify(data.data.data.caseStudies));
        sessionStorage.setItem('testId', data.data.data.id);
        sessionStorage.setItem('check', false);
        setIsModalOpen({ visible: true, code: data.data.data.code });
      },
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col xs={24}>
          <Row align='center'>
            <Title style={{ color: '#d23f57' }}>New Exam Registration</Title>
          </Row>
          <Form onFinish={submitInformation} form={form} layout='vertical'>
            <Card title={<Title level={4}>Basic Information</Title>} style={{ marginBottom: '1em' }}>
              <Row gutter={[8, 8]}>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item name='first_name' label='First Name' rules={[{ required: true, message: 'Please input first name!' }]}>
                    <Input size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item name='last_name' label='Last Name' rules={[{ required: true, message: 'Please input last name!' }]}>
                    <Input size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name='email'
                    label='Email'
                    rules={[
                      { required: true, message: 'Please input education email!' },
                      { type: 'email', message: 'Please provide valid email' },
                    ]}>
                    <Input size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item name='specialty' label='Specialty' rules={[{ required: true, message: 'Please input specialty!' }]}>
                    <Select size='large' showSearch optionFilterProp='children' onChange={handleSpecialtyChange}>
                      {SPECIALTY.map((specialty) => (
                        <Option value={specialty.id} key={specialty.id}>
                          {specialty.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item name='training_status' label='Training Status' rules={[{ required: true, message: 'Please input training status!' }]}>
                    <Select size='large'>
                      {medStudentSelected
                        ? MED_STUDENT_TRAINING_STATUS.map((training) => (
                            <Option value={training.id} key={training.id}>
                              {training.value}
                            </Option>
                          ))
                        : TRAINING_STATUS.map((training) => (
                            <Option value={training.id} key={training.id}>
                              {training.value}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card title={<Title level={4}>Send My Result To</Title>}>
              <Row gutter={[8, 8]}>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item name='program_name' label='Program Name' rules={[{ required: true, message: 'Please input program name!' }]}>
                    <Input size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item name='director_name' label='Program Director or Faculty Name' rules={[{ required: true, message: 'Please input program director or faculty name!' }]}>
                    <Input size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name='director_email'
                    label='Program Director or Faculty Email'
                    rules={[
                      { required: true, message: 'Please input email!' },
                      { type: 'email', message: 'Please provide a valid email!' },
                    ]}>
                    <Input size='large' />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row align='space-between'>
              <Link to='/'>
                <Button type='dashed' size='large' style={{ marginTop: '1em' }}>
                  Go Back
                </Button>
              </Link>

              <Button type='primary' size='large' htmlType='submit' style={{ marginTop: '1em' }}>
                Proceed to Disclaimer
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>

      <Modal title='Code To Resume Exam' open={isModalOpen.visible} footer={null} closable={false}>
        <p>
          In the event your internet connection is disrupted, please go to{' '}
          <a href='www.radme.org' target='_blank'>
            www.radme.org
          </a>
          , select "Resume Previous Exam" and enter your email and this one time code provided below and emailed to you. <br /> <br />
          <Text mark>IMPORTANT: </Text> The exam can only be resumed once.
        </p>

        <Paragraph
          style={{ fontSize: '2em', textAlign: 'center', fontWeight: 'bold' }}
          copyable={{
            tooltips: ['copy text', 'copied text'],
          }}>
          {isModalOpen.code}
        </Paragraph>

        <Row justify='end'>
          <Button type='primary' size='large' block onClick={() => navigate('/disclaimer')}>
            Proceed <ArrowRightOutlined />
          </Button>
        </Row>
      </Modal>
    </Spin>
  );
};

export default Information;
