import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, message, Row, Select, Space, Spin, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { modalityList } from '../../utils/Constant';
import { NewCaseStudy, UploadDiComFiles } from '../../api';

const { Title } = Typography;
const { Option } = Select;

const CreateCaseStudy = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { mutate: handleCreateCaseStudy, isLoading } = NewCaseStudy();
  const { mutate: handleUploadDiComFile, isLoading: uploadingDiComFiles } = UploadDiComFiles();

  const [showSeriesUploadCard, setShowSeriesUploadCard] = useState(false);
  const [diComCollections, setDiComCollections] = useState('');

  useEffect(() => {
    if (!sessionStorage.getItem('token')) {
      navigate('/login');
    }
  }, []);

  const handleResetFields = () => {
    form.resetFields();
    setDiComCollections('');
  };

  const onFinish = (value) => {
    const body = {
      hpi: value.hpi,
      vitals: value.vital,
      labs: value.labs,
      modality: value.modality,
      exam: value.exam,
      official_impression: value.official_impression,
      official_findings: value.official_findings,
    };

    handleCreateCaseStudy(body, {
      onSuccess: (data) => {
        console.log(data);
        message.success('Please upload DICOM series');
        sessionStorage.setItem('questionId', data?.data?.data?.id);
        setShowSeriesUploadCard(true);
      },
    });
  };

  const handleDiComUpload = () => {
    if (diComCollections.length > 0) {
      let formData = new FormData();

      for (const key of Object.keys(diComCollections)) {
        formData.append('dicom', diComCollections[key]);
      }

      handleUploadDiComFile(formData, {
        onSuccess: () => {
          message.success('Dicom series uploaded successfully!');
          handleResetFields();
        },
      });
    } else {
      message.warning('Please add dicom collection');
    }
  };

  return (
    <Spin spinning={isLoading || uploadingDiComFiles}>
      <div className='center'>
        <Title level={3}>Create Case Study</Title>
      </div>
      <Card style={{ marginBottom: '1em' }}>
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='hpi' label='History of Present Illness' rules={[{ required: true, message: 'Please select HPI' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='vital' label='Vital' rules={[{ required: true, message: 'Please select vital' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='labs' label='Labs' rules={[{ required: true, message: 'Please select labs' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='modality' label='Modality' rules={[{ required: true, message: 'Please select modality' }]}>
                <Select size='large'>
                  {modalityList.map((el) => (
                    <Option key={el} value={el}>
                      {el}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='exam' label='Exam Name' rules={[{ required: true, message: 'Please input exam name' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='official_impression' label='Official Impression' rules={[{ required: true, message: 'Please select official impression' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='official_findings' label='Official Findings' rules={[{ required: true, message: 'Please select official findings' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Space size='middle'>
              <Button type='primary' htmlType='submit' size='large'>
                Next
              </Button>
              <Button onClick={handleResetFields} size='large'>
                Reset Fields
              </Button>
            </Space>
          </Row>
        </Form>
      </Card>

      {showSeriesUploadCard && (
        <Card>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <input type='file' name='imgCollection' onChange={(e) => setDiComCollections(e.target.files)} multiple />
            </Col>
            {diComCollections !== '' && (
              <Col xs={24}>
                <Button onClick={handleDiComUpload} icon={<UploadOutlined />}>
                  Upload Series
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      )}
    </Spin>
  );
};

export default CreateCaseStudy;
