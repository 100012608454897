import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, message, Row, Select, Spin, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import { modalityList } from '../../utils/Constant';
import { GetSingleCaseStudy, UploadDiComFiles } from '../../api';

const { Title } = Typography;
const { Option } = Select;

const CaseStudyDetails = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const { data, isLoading } = GetSingleCaseStudy(id);
  const { mutate: handleUploadDiComFile, isLoading: uploadingDiComFiles } = UploadDiComFiles();

  const [dicomCollections, setDicomCollections] = useState('');

  useEffect(() => {
    form.setFieldsValue({
      hpi: data?.hpi,
      vital: data?.vitals,
      labs: data?.labs,
      modality: data?.modality,
      exam: data?.exam,
      official_impression: data?.official_impression,
      official_findings: data?.official_findings,
    });
  }, [form, id]);

  const handleDicomUpload = () => {
    let formData = new FormData();

    for (const key of Object.keys(dicomCollections)) {
      formData.append('dicom', dicomCollections[key]);
    }

    handleUploadDiComFile(formData, {
      onSuccess: () => {
        message.success('Dicom series uploaded successfully!');
      },
    });
  };

  return (
    <Spin spinning={isLoading || uploadingDiComFiles}>
      <div className='center'>
        <Title level={3}>Case Study Details</Title>
      </div>
      <Card style={{ marginBottom: '1em' }}>
        <Form form={form} layout='vertical'>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='hpi' label='History of Present Illness' rules={[{ required: true, message: 'Please select HPI' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='vital' label='Vital' rules={[{ required: true, message: 'Please select vital' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='labs' label='Labs' rules={[{ required: true, message: 'Please select labs' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='modality' label='Modality' rules={[{ required: true, message: 'Please select modality' }]}>
                <Select size='large'>
                  {modalityList.map((el) => (
                    <Option key={el} value={el}>
                      {el}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='exam' label='Exam Name' rules={[{ required: true, message: 'Please input exam name' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='official_impression' label='Official Impression' rules={[{ required: true, message: 'Please select official impression' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item name='official_findings' label='Official Findings' rules={[{ required: true, message: 'Please select official findings' }]}>
                <Input size='large' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <input size='large' type='file' name='imgCollection' onChange={(e) => setDicomCollections(e.target.files)} multiple />
          </Col>
          {dicomCollections !== '' && (
            <Col xs={24}>
              <Button onClick={handleDicomUpload} icon={<UploadOutlined />}>
                Upload Series
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    </Spin>
  );
};

export default CaseStudyDetails;
