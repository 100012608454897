import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { ResumeLastExam } from '../api';

const { Title } = Typography;

const ResumeExam = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { mutate, isLoading } = ResumeLastExam();

  const handleResumeExam = (value) => {
    const body = {
      email: value.email,
      code: value.code,
    };

    mutate(body, {
      onSuccess: (data) => {
        sessionStorage.setItem('cases', JSON.stringify(data.data.data.caseStudies));
        sessionStorage.setItem('testId', data.data.data.id);
        sessionStorage.setItem('remaining', moment().add(data.data.data.remaining_mins, 'm'));
        navigate(`/history/${data.data.data.caseStudies[0].value}`);
      },
    });
  };

  return (
    <Row>
      <Col xs={24}>
        <Row justify='center'>
          <Title style={{ color: '#d23f57' }}>Resume Previous Exam</Title>
        </Row>
        <Form form={form} onFinish={handleResumeExam} layout='vertical'>
          <Card>
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={12}>
                <Form.Item name='email' label='Your Email' rules={[{ required: true, message: 'Please input email!' }]}>
                  <Input size='large' />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name='code' label='Code' rules={[{ required: true, message: 'Please input code!' }]}>
                  <Input size='large' />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='center'>
              <Button type='primary' htmlType='submit' size='large' loading={isLoading}>
                Resume Previous Exam
              </Button>
            </Row>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default ResumeExam;
