import { Button, Spin, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { GetTestListForAdmin } from '../../api';

const { Title } = Typography;

const ExamineeList = () => {
  const { data: studentList, isLoading } = GetTestListForAdmin();
  console.log(studentList);

  const columns = [
    {
      title: 'Student Information',
      children: [
        {
          title: 'First Name',
          dataIndex: ['candidate', 'f_name'],
        },
        {
          title: 'Last Name',
          dataIndex: ['candidate', 'l_name'],
        },
        {
          title: 'Email',
          dataIndex: ['candidate', 'email'],
        },
        {
          title: 'Specialty',
          dataIndex: ['candidate', 'specialty'],
        },
        {
          title: 'Training Status',
          dataIndex: ['candidate', 'training_status'],
        },
      ],
    },
    {
      title: 'Program Information',
      children: [
        {
          title: 'Program Name',
          dataIndex: 'program_name',
        },
        {
          title: 'Director / Faculty Name',
          dataIndex: 'director_name',
        },
        {
          title: 'Director / Faculty Email',
          dataIndex: 'director_email',
        },
      ],
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (_, render) => (
        <Link to={`/admin/exam-details/${render.id}`}>
          <Button type='dashed' danger>
            Test Details
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Title level={2} className='center'>
        Student List
      </Title>
      <Table bordered columns={columns} dataSource={studentList} scroll={{ x: 2000 }} />
    </Spin>
  );
};

export default ExamineeList;
