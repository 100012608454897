import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Spin, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import { EndTheExam, GetSingleCaseStudyForStudent, SyncCaseStudiesWithTime } from '../../api';
import Notification from '../../utils/Notification';

const { Title } = Typography;

const CaseName = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // calculate remaining minutes from now to end
  const nowTime = moment(new Date());
  const endTime = moment(sessionStorage.getItem('remaining'));
  const duration = moment.duration(endTime.diff(nowTime));
  const remainingMinutes = parseInt(duration.asMinutes());

  const { mutate: caseStudyDetails, isLoading } = GetSingleCaseStudyForStudent();
  const { mutate: submitTest, isLoading: endingExam } = EndTheExam();
  SyncCaseStudiesWithTime(sessionStorage.getItem('testId'), remainingMinutes);

  const [caseStudyData, setCaseStudyData] = useState();
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    setTimer();
    const body = {
      skill_test_id: sessionStorage.getItem('testId'),
      q_id: id,
    };

    caseStudyDetails(body, {
      onSuccess: (data) => {
        setCaseStudyData(data.data.data);
      },
    });
  }, [id]);

  const setTimer = () => {
    const countTime = setInterval(() => {
      const distance = moment(sessionStorage.getItem('remaining')).valueOf() - moment().valueOf();

      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (minutes < 10 && seconds > 9) setRemainingTime(`0${hours}:0${minutes}:${seconds}`);
      else if (minutes < 10 && seconds < 9) setRemainingTime(`0${hours}:0${minutes}:0${seconds}`);
      else if (seconds < 10) setRemainingTime(`0${hours}:${minutes}:0${seconds}`);
      else setRemainingTime(`0${hours}:${minutes}:${seconds}`);

      if (distance < 0) {
        setRemainingTime(`00:00:00`);
        clearInterval(countTime);

        submitTest(
          { skill_test_id: sessionStorage.getItem('testId') },
          {
            onSuccess: () => {
              Modal.info({
                title: <Title level={3}>Time Up!!!</Title>,
                content: <Title level={4}>The exam has ended. Your answer have been saved. The results will be made available to your program director / faculty physician.</Title>,
                onOk() {
                  navigate('/');
                  sessionStorage.clear();
                },
                width: 600,
              });
            },
          }
        );
      }
    }, 1000);
  };

  const previousCase = () => {
    const QuesSet = JSON.parse(sessionStorage.getItem('cases'));
    const currentPos = QuesSet.findIndex((el) => el.value === +id);
    navigate(`/history/${QuesSet[currentPos - 1]?.value}`);
  };

  return (
    <Spin spinning={isLoading || endingExam}>
      <Title level={4} style={{ color: '#fff' }}>
        Remaining Time: {remainingTime}
      </Title>
      <Row>
        <Col xs={24}>
          <div style={{ height: '80vh', display: 'grid', placeItems: 'center' }}>
            <ul>
              <Title style={{ color: '#CBCCCC' }}>Case History</Title>
              <li>
                <Title style={{ color: '#CBC' }} level={2}>
                  HPI: {caseStudyData?.hpi}
                </Title>
              </li>
              <li>
                <Title style={{ color: '#CBC' }} level={2}>
                  Vitals: {caseStudyData?.vitals}
                </Title>
              </li>
              <li>
                <Title style={{ color: '#CBC' }} level={2}>
                  Labs: {caseStudyData?.labs}
                </Title>
              </li>
              <li>
                <Title style={{ color: '#CBC' }} level={2}>
                  Exam: {caseStudyData?.exam}
                </Title>
              </li>
            </ul>
          </div>
          <Row justify='space-between'>
            {JSON.parse(sessionStorage.getItem('cases'))[0].value === +id ? (
              <div />
            ) : (
              <Button type='primary' size='large' onClick={previousCase} style={{ backgroundColor: 'lightgray', color: 'black', fontWeight: 500 }}>
                Previous Case
              </Button>
            )}
            <Link to={`/exam/${id}`}>
              <Button size='large' type='primary'>
                To Case <ArrowRightOutlined />
              </Button>
            </Link>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default CaseName;
