import { Layout, Typography } from 'antd';

import { Link, Outlet, useLocation } from 'react-router-dom';

const { Content, Header } = Layout;
const { Title } = Typography;

const CommonLayout = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <Layout>
      <Header className='header-style'>
        <Link to={pathname === '/exam/review' ? '/exam/review' : '/'}>
          <Title level={4} style={{ marginBottom: 0 }}>
            Radme
          </Title>
        </Link>
      </Header>
      <Content className='content-style' style={{ minHeight: '92vh' }}>
        {children ? children : <Outlet />}
      </Content>
    </Layout>
  );
};

export default CommonLayout;
