import { ArrowLeftOutlined, ArrowRightOutlined, FullscreenExitOutlined, FullscreenOutlined, MenuOutlined, StopOutlined } from "@ant-design/icons";

import { Button, Col, Drawer, Form, Input, Layout, message, Row, Tour } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrepMenuTopics from "../../components/PrepMenuTopics";

const { Content, Header } = Layout;

const StudentPrep = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { prepType, questionId } = useParams();

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const prepQuestions = JSON.parse(sessionStorage.getItem("prepQuestions"));

  const [showAnswer, setShowAnswer] = useState(false);
  const [radFileUrl, setRadFileUrl] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showModal, setShowModal] = useState(() => {
    return !sessionStorage.getItem("prepModalShown");
  });

  const [navIsVisible, setNavIsVisible] = useState(false);

  const closeSidenav = () => {
    setNavIsVisible(false);
  };

  const openSidenav = () => {
    setNavIsVisible(true);
  };

  const onEndPreparationClick = () => {
    sessionStorage.removeItem("prepQuestions");
    navigate("/medical-student");
  };

  useEffect(() => {
    setShowAnswer(false);
    form.resetFields();
    setRadFileUrl("");

    const currentQuestion = prepQuestions?.find((el) => el.id === +questionId);

    if (currentQuestion) {
      form.setFieldsValue({
        history: currentQuestion.history,
        correct_diagnosis: currentQuestion.diagnosis,
      });
      setRadFileUrl(currentQuestion.json_url);
    }
  }, [questionId]);

  useEffect(() => {
    if (showModal) {
      sessionStorage.setItem("prepModalShown", "true");
    }
  }, [showModal]);

  const exitHandler = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      // closeFullscreen();
      setIsFullScreen(false);
    }
  };

  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  const currentQuestionIndex = prepQuestions?.findIndex((el) => el.id === +questionId);

  const onShowAnswer = () => {
    if (form.getFieldValue("password") === "radmenow") {
      setShowAnswer(true);
    } else {
      message.error("Incorrect password");
    }
  };

  const onPreviousClick = () => {
    navigate(`/student-prep/${prepType}/${prepQuestions[currentQuestionIndex - 1].id}`);
  };

  const onNextClick = () => {
    navigate(`/student-prep/${prepType}/${prepQuestions[currentQuestionIndex + 1].id}`);
  };

  const handleFullSCreen = () => {
    if (isFullScreen) {
      closeFullscreen();
      setIsFullScreen(false);
    } else {
      openFullScreen();
      setIsFullScreen(true);
    }
  };

  const openFullScreen = () => {
    let elem = document.getElementById("rou");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  const handleDoubleClick = () => {
    handleFullSCreen();
  };

  const steps = [
    {
      title: "Review case",
      description:
        "This module contains 20 cases. Review each case individually and ask your supervising resident to unlock the password-protected true diagnosis, which also unlocks the next case. Together try to engage in an in depth discussion of the findings",
      target: () => ref1.current,
      placement: "left",
    },
    {
      title: "Unlock answer to go to next case",
      description: "Once the resident enters the password, the “next” button will appear and you can proceed to the next case.",
      target: () => ref2.current,
      placement: "bottomRight",
    },
    {
      title: "Need to leave and come back?",
      description: "Use the top left menu to resume from your last case.",
      target: () => ref3.current,
      placement: "leftTop",
    },
  ];

  return (
    <>
      <Layout>
        <Drawer title="RadMe" placement="left" onClose={closeSidenav} open={navIsVisible}>
          <PrepMenuTopics onClick={closeSidenav} />
        </Drawer>
        <Header className="header-style">
          <Button ref={ref3} className="menu" type="primary" icon={<MenuOutlined />} onClick={openSidenav} />

          <Button type="primary" size="large" icon={<StopOutlined />} onClick={onEndPreparationClick}>
            End Preparation
          </Button>
        </Header>
        <Content style={{ minHeight: "92vh", padding: "12px", background: "#000", color: "#E3E3E7" }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={18}>
              <div id="rou" onDoubleClick={handleDoubleClick}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {isFullScreen ? (
                    <FullscreenExitOutlined onClick={handleFullSCreen} style={{ fontSize: "2em", padding: "1em 1em 0 1em" }} />
                  ) : (
                    <FullscreenOutlined onClick={handleFullSCreen} style={{ fontSize: "2em", padding: "0 1em 0 1em" }} />
                  )}
                </div>

                <embed src={radFileUrl} style={{ width: "100%", height: `${isFullScreen ? "92vh" : "82vh"}` }} />
              </div>
            </Col>
            <Col ref={ref1} xs={24} md={6}>
              <Form form={form} layout="vertical">
                <label style={{ color: "#CBC", fontSize: "20px" }} htmlFor="history">
                  History
                </label>
                <Form.Item name="history">
                  <Input.TextArea readOnly rows={2} autoSize={{ minRows: 2, maxRows: 6 }} style={{ minWidth: "100%" }} />
                </Form.Item>
                <label style={{ color: "#CBC", fontSize: "20px" }} htmlFor="your_diagnosis">
                  Your Diagnosis
                </label>
                <Form.Item name="your_diagnosis">
                  <Input.TextArea rows={2} autoSize={{ minRows: 2, maxRows: 6 }} style={{ minWidth: "100%" }} />
                </Form.Item>
                <label style={{ color: "#CBC", fontSize: "20px" }} htmlFor="correct_diagnosis">
                  Correct Diagnosis
                </label>
                {showAnswer ? (
                  <Form.Item name="correct_diagnosis">
                    <Input.TextArea readOnly rows={2} autoSize={{ minRows: 2, maxRows: 6 }} style={{ minWidth: "100%" }} />
                  </Form.Item>
                ) : (
                  <>
                    <Form.Item name="password">
                      <Input.Password placeholder="Enter password to view answer" />
                    </Form.Item>
                    <Button type="primary" onClick={onShowAnswer}>
                      Show Answer
                    </Button>
                  </>
                )}
              </Form>
            </Col>
          </Row>
          <Row justify="space-between">
            <Button size="large" type="primary" style={{ marginTop: "1em" }} icon={<ArrowLeftOutlined />} disabled={currentQuestionIndex === 0} onClick={onPreviousClick}>
              Previous
            </Button>
            <Button
              ref={ref2}
              size="large"
              type="primary"
              disabled={!showAnswer || currentQuestionIndex === prepQuestions.length - 1}
              style={{ marginTop: "1em" }}
              icon={<ArrowRightOutlined />}
              onClick={onNextClick}>
              Next
            </Button>
          </Row>

          <Tour open={showModal} onClose={() => setShowModal(false)} steps={steps} />
        </Content>
      </Layout>
    </>
  );
};

export default StudentPrep;
