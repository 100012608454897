import { useState } from 'react';
import { Button, Drawer, Layout } from 'antd';
import { MenuOutlined, StopOutlined } from '@ant-design/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import ExamMenuTopics from '../components/ExamMenuTopics';

const { Content, Header } = Layout;

const ExamLayout = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [navIsVisible, setNavIsVisible] = useState(false);

  const closeSidenav = () => {
    setNavIsVisible(false);
  };

  const openSidenav = () => {
    setNavIsVisible(true);
  };

  return (
    <Layout>
      <Drawer title='RadMe' placement='left' onClose={closeSidenav} open={navIsVisible}>
        <ExamMenuTopics onClick={closeSidenav} />
      </Drawer>
      <Header className='header-style'>
        <Button className='menu' type='primary' icon={<MenuOutlined />} onClick={openSidenav} />
        {pathname !== '/exam/review' ? (
          <Button type='primary' size='large' icon={<StopOutlined />} onClick={() => navigate('/exam/review')}>
            End Exam
          </Button>
        ) : null}
      </Header>
      <Content style={{ minHeight: '92vh', padding: '12px', background: '#000', color: '#E3E3E7' }}>{children ? children : <Outlet />}</Content>
    </Layout>
  );
};

export default ExamLayout;
