import { Avatar, Dropdown, Layout, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link, Outlet, useNavigate } from 'react-router-dom';

const { Content, Header } = Layout;
const { Title } = Typography;

const AdminLayout = ({ children }) => {
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.clear();
    navigate('/login');
  };

  const items = [
    { key: 'examinee', label: <Link to='/admin/examinee'>All Students</Link> },
    { key: 'create-case-study', label: <Link to='/admin/create-case-study'>New Case Study</Link> },
    { key: 'case-study-list', label: <Link to='/admin/case-study-list'>Case Study List</Link> },
    { key: 'logout', label: <div onClick={logout}>Logout</div> },
  ];

  return (
    <Layout>
      <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title level={4} style={{ marginTop: '1em' }}>
          Radme
        </Title>
        <Dropdown menu={{ items }} placement='topRight'>
          <Avatar style={{ backgroundColor: '#d23f57', marginTop: '1em' }} icon={<UserOutlined />} />
        </Dropdown>
      </Header>
      <Content className='content-style' style={{ marginTop: '2em', minHeight: '92vh' }}>
        {children ? children : <Outlet />}
      </Content>
    </Layout>
  );
};

export default AdminLayout;
