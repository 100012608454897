import { useEffect, useState } from 'react';
import { Button, Card, Col, Input, message, Modal, Result, Row, Spin, Typography } from 'antd';
import { ArrowRightOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { EndTheExam, GetTestCasesForReviewExam, SaveCurrentCase, SyncCaseStudiesWithTime } from '../../api';
import Notification from '../../utils/Notification';

const { Title } = Typography;
const { confirm } = Modal;

const ReviewExam = () => {
  const navigate = useNavigate();

  const { mutate: submitTest, isLoading: isSubmittingTest } = EndTheExam();
  const { mutate: saveTest, isLoading: savingTest } = SaveCurrentCase();
  const { data: testCases, isLoading: isGettingTestCases } = GetTestCasesForReviewExam(sessionStorage.getItem('testId'));

  const [answeredInformation, setAnsweredInformation] = useState({ findings: '', impression: '' });
  const [remainingTime, setRemainingTime] = useState('');

  // calculate remaining minutes from now to end
  const nowTime = moment(new Date());
  const endTime = moment(sessionStorage.getItem('remaining'));
  const duration = moment.duration(endTime.diff(nowTime));
  const remainingMinutes = parseInt(duration.asMinutes());

  SyncCaseStudiesWithTime(sessionStorage.getItem('testId'), remainingMinutes);

  useEffect(() => {
    setTimer();

    if (sessionStorage.getItem('check') === 'false')
      Modal.info({
        title: 'Important Note',
        content: (
          <div>
            <p>
              Please do not forget to click <strong>Update Answer</strong> each time you make changes to the findings or impression.
            </p>
          </div>
        ),
        okText: 'I Understand',
        onOk() {
          sessionStorage.setItem('check', true);
        },
      });
  }, []);

  const setTimer = () => {
    const countTime = setInterval(() => {
      const distance = moment(sessionStorage.getItem('remaining')).valueOf() - moment().valueOf();

      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (minutes < 10 && seconds > 9) setRemainingTime(`0${hours}:0${minutes}:${seconds}`);
      else if (minutes < 10 && seconds < 9) setRemainingTime(`0${hours}:0${minutes}:0${seconds}`);
      else if (seconds < 10) setRemainingTime(`0${hours}:${minutes}:0${seconds}`);
      else setRemainingTime(`0${hours}:${minutes}:${seconds}`);

      if (distance < 0) {
        setRemainingTime(`00:00:00`);
        clearInterval(countTime);

        submitTest(
          { skill_test_id: sessionStorage.getItem('testId') },
          {
            onSuccess: () => {
              Modal.info({
                title: <Title level={3}>Time Up!!!</Title>,
                content: <Title level={4}>The exam has ended. Your answer have been saved. The results will be made available to your program director / faculty physician.</Title>,
                onOk() {
                  navigate('/');
                  sessionStorage.clear();
                },
                width: 600,
              });
            },
          }
        );
      }
    }, 1000);
  };

  const handleOnInputFieldChange = (prop) => (e) => setAnsweredInformation({ ...answeredInformation, [prop]: e.target.value });

  const previousCase = () => {
    const QuesSet = JSON.parse(sessionStorage.getItem('cases'));
    const currentPos = QuesSet[QuesSet.length - 1];
    navigate(`/history/${currentPos?.value}`);
  };

  const handleEndExam = () => {
    confirm({
      title: 'You are about to end your exam!',
      icon: <ExclamationCircleFilled />,
      content: 'Your data will be saved. Do you wish to continue?',
      okText: 'End Exam',
      onOk() {
        submitTest(
          { skill_test_id: sessionStorage.getItem('testId') },
          {
            onSuccess: () => {
              sessionStorage.clear();
              navigate('/');
              Notification('Thank you for taking Radiology Skill Test. Your results will be emailed to your program/ faculty physician', 'success');
            },
          }
        );
      },
    });
  };

  const handleUpdateAnswer = (value) => {
    if (answeredInformation.findings === '' && answeredInformation.impression === '') {
      return message.error('You must change the answer to update!');
    }

    const selectedAnswer = testCases?.find(({ q_id }) => q_id === value.q_id);

    const body = {
      skill_test_id: sessionStorage.getItem('testId'),
      q_id: value.q_id,
    };

    if (answeredInformation.findings.trim() === '') {
      body.findings = selectedAnswer.question.answer?.findings;
    } else {
      body.findings = answeredInformation.findings;
    }

    if (answeredInformation.impression.trim() === '') {
      body.impression = selectedAnswer.question.answer?.impression;
    } else {
      body.impression = answeredInformation.impression;
    }

    saveTest(body, {
      onSuccess: () => {
        message.success('Your answer was saved!');
      },
    });
  };

  return (
    <div className='content-style'>
      <Spin spinning={savingTest || isSubmittingTest || isGettingTestCases}>
        <Title level={4} style={{ color: '#fff', paddingTop: 8 }}>
          Remaining Time: {remainingTime}
        </Title>
        <Row>
          <Col xs={24}>
            <Row align='center'>
              <Title style={{ color: '#d23f57' }}>Review Exam</Title>
            </Row>
            {/* cases */}
            {testCases?.length > 0 ? (
              <>
                {testCases?.map((cases, index) => (
                  <Card
                    className='mt-1'
                    key={index}
                    title={`Case ${index + 1}`}
                    extra={
                      <Link to={`/history/${cases.q_id}`}>
                        <Button type='dashed' style={{ color: 'red', borderColor: 'red' }}>
                          Go To Case <ArrowRightOutlined />
                        </Button>
                      </Link>
                    }>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={12}>
                        <label htmlFor='findings'>Findings</label>
                        <Input.TextArea rows={1} autoSize defaultValue={cases?.question?.answer?.findings} size='large' onChange={handleOnInputFieldChange('findings')} />
                      </Col>
                      <Col xs={24} sm={12}>
                        <label htmlFor='impression'>Impression</label>
                        <Input.TextArea rows={1} autoSize defaultValue={cases?.question?.answer?.impression} size='large' onChange={handleOnInputFieldChange('impression')} />
                      </Col>
                    </Row>
                    <Row justify='center' className='mt-1'>
                      <Button type='primary' style={{ backgroundColor: '#045CBC', color: '#fff' }} onClick={() => handleUpdateAnswer(cases)}>
                        Update Answer
                      </Button>
                    </Row>
                  </Card>
                ))}
                <Row justify='space-between' className='mt-1'>
                  <Button type='primary' size='large' onClick={previousCase} style={{ backgroundColor: 'lightgray', color: 'black', fontWeight: 500 }}>
                    Previous Case
                  </Button>
                  <Button size='large' type='primary' onClick={handleEndExam}>
                    Submit & End Exam
                  </Button>
                </Row>
              </>
            ) : (
              <Row justify='center'>
                <Result
                  status='warning'
                  title='Please take the exam first to review your answers.'
                  extra={
                    <Link to='/information'>
                      <Button type='primary' size='large'>
                        Go To Exam Registration
                      </Button>
                    </Link>
                  }
                />
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ReviewExam;
