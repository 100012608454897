import { Button, Card, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const Tutorial = () => {
  return (
    <Row>
      <Col xs={24}>
        <Row justify='center'>
          <Title style={{ color: '#d23f57' }}>Tutorial</Title>
        </Row>

        <Card>
          <Title level={4} style={{ fontWeight: 400 }}>
            Tutorial Coming Soon
          </Title>
        </Card>

        <Row justify='space-between' className='mt-1'>
          <Link to='/'>
            <Button size='large' type='dashed'>
              Go Back
            </Button>
          </Link>
          <Link to='/information'>
            <Button size='large' type='primary'>
              Start New Exam Registration
            </Button>
          </Link>
        </Row>
      </Col>
    </Row>
  );
};

export default Tutorial;
